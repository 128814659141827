import React, {useState} from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section } from "../components/common/section"
import Feature from "../components/feature"

import visual404 from "../images/visual-404.svg"

const NotFoundPage = () => {

  const [currentBreakPoint, setCurrentBreakpointChildren] = useState({
    type: null,
    bp: null
  });

  return (
    <Layout
      socialDark={true}
      currentPage='404'
      updateBreakpoint={setCurrentBreakpointChildren}
    >
      
      <SEO title="404: Not found" />

      <Section>
        {currentBreakPoint.bp !== "xs" && currentBreakPoint.bp !== "s" && currentBreakPoint.bp !== "m" ? (
          <div style={{
            position: 'absolute',
            zIndex: '1',
            width: '56%',
            paddingBottom: '46%',
            top: '200px',
            right: '0',
            background: `transparent url(${visual404}) no-repeat 0 50%`,
            backgroundSize: 'contain',
          }} />
        ) : null}

        <Feature 
          title="There’s nothing to see here. But we do like you. We’re happy to bring you back home"
          url="/"
          link="Home is where you ‘re not lost anymore"
          content404={true}
        />
      </Section>
    </Layout>
  )
}

export default NotFoundPage
