import styled from 'styled-components';
import variables, { mq, fs, screenSize } from '../../utils/variables';

export const Container = styled.div`
  display: flex;
  justify-content: ${props => props.alignOn === 'left' ? 'flex-start' : 'center'};
  align-items: center;
  margin: 0 auto;
  

  ${props => props.content404 ? `
    padding: 120px 4vw 90px calc(7vw + 100px);
    min-height: calc(80vh - 190px);
  ` : `
    max-width: 65vw;
    padding: 120px 4vw 90px;
  ` }

  ${mq('m')} {
    max-width: 100vw;
    padding: 90px 35px 60px;
  }

  ${mq('s')} {
    max-width: 100vw;
    padding: 60px 20px 40px;

    ${props => props.minispace ? `
      padding: 0 20px 0;
    ` : null}
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
  align-items: center;
  justify-content: space-between;
  ${props => props.contentFull ? `
    width: 100%;
  ` : `` }

  ${mq('s')} {
    /* flex-direction: ${props => props.reverse ? 'column-reverse' : 'column'}; */
    flex-direction: column-reverse;
  }
  
  .content {
    max-width: 42%;
    ${props => props.contentFull ? `
      width: 100%;
    ` : `` }

    ${props => props.extraspace ? `
      max-width: 50%;
      ${screenSize('padding-top', 150)}
      ${screenSize('padding-bottom', 300)}
    ` : null}

    ${props => props.content404 ? `
      max-width: 45%;
      ${screenSize('padding-top', 50)}
      ${screenSize('padding-bottom', 150)}
    ` : null}

    

    ${mq('m')} {
      max-width: 55%;

      ${props => props.minispace ? `
        padding-top: 0;
        padding-bottom : 0;
      ` : null}
    }

    ${mq('s')} {
      max-width: 100%;

      ${screenSize('padding-top', 0)}
      ${screenSize('margin-top', -20)}
      ${screenSize('padding-bottom', 75)}
    }
  }

  .visual {
    max-width: 50%;

    img {
      display: block;
      margin-bottom: 0;
    }

    ${mq('m')} {
      max-width: 35%;
    }

    ${mq('s')} {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    &--embed {
      max-width: calc(50% + 27vw);
      ${props => props.reverse ? `margin-left: -27vw;` : `margin-right: -27vw;`}
      box-shadow: 0px 9px 90px rgba(7, 13, 28, 0.45);
      border-radius: 1.3vw;
      border: 1.3vw solid ${variables.colors.backgroundDark};

      ${mq('s')} {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4vw;
      }
    }

    &--big {
      /* width: ${props => props.contentFull ? `calc(100% + 27vw)` : `auto`}; */
      ${props => props.contentFull ? `
        width: calc(100%);
      ` : `` }
      max-width: calc(40% + 27vw);
      ${props => props.reverse ? `margin-left: -22vw;` : `margin-right: -22vw;`}
      border-radius: 1.3vw;

      ${mq('s')} {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4vw;
      }
    }
  }

  .headline {
    display: block;
    margin-bottom: 0.7vw;
    font-family: 'Bossa', sans-serif;
    font-weight: 700;
    line-height: 1.5;
    color: ${props => props.dark ? variables.colors.green : variables.colors.blue};

    ${fs(18)}

    ${mq('m')} {
      margin-bottom: 10px;
    }
  }

  .title {
    margin-bottom: 1.5vw;
    font-family: 'Bossa', sans-serif;
    font-weight: 500;
    line-height: 1.3;
    color: ${props => props.dark ? variables.colors.white : variables.colors.backgroundDark};

    ${fs(36)}

    ${mq('m')} {
      margin-bottom: 20px;
    }

    ${props => props.content404 ? `
      ${fs(52)}
      font-weight: 700;
    ` : null }
  }

  .text {
    margin-bottom: 2.4vw;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    line-height: 1.7;
    color: ${props => props.dark ? variables.colors.white : variables.colors.backgroundDark};

    ${fs(18)}

    ${mq('m')} {
      margin-bottom: 25px;
    }
  }

  .link {
    font-family: 'Bossa', sans-serif;
    font-weight: 700;
    line-height: 1.5;
    color: ${variables.colors.red};
    text-decoration: none;
    white-space: nowrap;

    ${fs(18)}

    svg {
      margin: 0 1vw;
      transition: all .3s ease-in;

      ${mq('m')} {
        margin: 0 10px;
      }
    }

    svg path {
      fill: ${variables.colors.red};
    }

    &:hover {
      color: ${variables.colors.blue};

      svg {
        margin-left: calc(1vw + 10px);
        
        ${mq('m')} {
          margin-left: 20px;
        }
      }

      svg path {
      fill: ${variables.colors.blue};
    }
    }
  }
`;