import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import LinkTransition from "../common/link-transition"
import bodymovin from "lottie-web"
import sal from "sal.js"
import Image from "../image"

import { Container, Content } from "./styled.js"

const Feature = props => {
  const animation = React.createRef()
  let lottieAnimation = useRef()

  useEffect(() => {
    sal()

    if (lottieAnimation && lottieAnimation.current) {
      bodymovin.loadAnimation({
        useSubFrames: false,
        wrapper: lottieAnimation.current,
        renderer: "svg",
        loop: true,
        path: props.animation,
      })
    }

    if (animation && animation.current) {
      animation.current.addEventListener("sal:in", () => {})

      animation.current.addEventListener("sal:out", () => {})
    }
  }, [])

  return (
    <div style={{ position: "relative" }}>
      {props.children}
      <Container
        style={{ position: "relative" }}
        content404={props.content404}
        contentFull={props.contentFull}
      >
        <Content
          content404={props.content404}
          reverse={props.reverse}
          dark={props.dark}
          extraspace={props.extraspace}
          minispace={props.minispace}
          contentFull={props.contentFull}
        >
          <div className="content">
            {props.headline ? (
              <span
                className="headline"
                data-sal={`slide-up`}
                data-sal-duration="500"
              >
                {props.headline}
              </span>
            ) : null}
            {props.title ? (
              <h2 className="title" data-sal={`slide-up`}>
                {props.title}
              </h2>
            ) : null}
            {props.text ? (
              <p className="text" data-sal={`slide-up`}>
                {props.text}
              </p>
            ) : null}
            {props.textHtml ? (
              <div
                className="text"
                data-sal={`slide-up`}
                dangerouslySetInnerHTML={{ __html: props.textHtml }}
              />
            ) : null}
            {props.url ? (
              <LinkTransition
                to={props.url}
                className="link"
                data-sal={`slide-up`}
                title={`${props.link}: ${props.title}`}
              >
                {props.link}
                <span className="accessibility-hidden"> ({props.title})</span>
                <svg
                  width="23"
                  height="10"
                  viewBox="0 0 23 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.7053 6.63864V8.60241C16.7053 9.70557 18.0391 10.258 18.8191 9.47798L22.021 6.27614C22.5046 5.79258 22.5045 5.00857 22.021 4.525L18.8191 1.32316C18.0391 0.543113 16.7053 1.09558 16.7053 2.19874V4.16215H1.8464C1.16254 4.16215 0.608154 4.71653 0.608154 5.40039C0.608154 6.08425 1.16254 6.63864 1.8464 6.63864H16.7053Z"
                  />
                </svg>
              </LinkTransition>
            ) : null}
          </div>
          {!props.contentFull ? (
            <div
              className={`visual ${
                props.visualType ? `visual--${props.visualType}` : ""
              }`}
              data-sal={props.visualNotAppear ? "none" : "fade"}
              ref={animation}
              data-sal-repeat={props.animationRepeat}
            >
              {props.animation ? (
                <div
                  ref={lottieAnimation}
                  style={{ width: "100%", height: "100%" }}
                />
              ) : null}
            </div>
          ) : null}

          {props.visual ? (
            <div
              className={`visual ${
                props.visualType ? `visual--${props.visualType}` : ""
              }`}
              data-sal={props.visualNotAppear ? "none" : "fade"}
              data-sal-repeat={props.animationRepeat}
            >
              <Image
                filename={props.visual}
                alt={`Illustration of ${
                  props.headline ? props.headline : "feature"
                }`}
              />
              {/* <img src={props.visual} alt={`Illustration of ${props.headline ? props.headline : 'feature'}`} /> */}
            </div>
          ) : null}
        </Content>
      </Container>
    </div>
  )
}

Feature.propTypes = {
  dark: PropTypes.bool,
  children: PropTypes.node,
}

Feature.defaultProps = {
  dark: false,
}

export default Feature
